import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { ReactComponent as CaretRight } from '../images/icon-caret-right.svg'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'
import Text from './text'

const NavButton = ({ enabled, onClick, reversed }) => (
  <button
    css={css`
      ${tw`flex items-center justify-center w-12 h-12`}
      ${reversed && tw`transform rotate-180`}
      svg {
        ${tw`transition transition-transform duration-300`}
        path {
          ${tw`text-opacity-100 fill-current text-primary-500`}
        }
      }
      &:hover {
        svg {
          ${tw`transform translate-x-1`}
        }
      }
      &:disabled {
        ${tw`pointer-events-none`}
        path {
          ${tw`text-opacity-40`}
        }
      }
    `}
    type="button"
    onClick={onClick}
    disabled={!enabled}
  >
    <CaretRight />
  </button>
)

NavButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  reversed: PropTypes.bool,
}

const CardCarousel = ({ cards, style }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: false,
    speed: 5,
    align: 'start',
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)
  }, [embla, onSelect])

  return (
    <div>
      <div
        css={[
          css`
            ${tw`block overflow-visible`}
            &.is-draggable {
              ${tw`cursor-grab`}
            }
            &.is-dragging {
              ${tw`cursor-grabbing`}
            }
          `,
        ]}
        ref={viewportRef}
      >
        <div css={[tw`flex flex-row flex-nowrap`, style]}>
          {cards.map((card, i) => (
            <a
              href={card?.callToAction?.link}
              key={i}
              css={[
                tw`flex flex-col items-start flex-none px-6 pt-14 pb-32 mr-6 w-80
                lg:(px-10 pt-16 pb-20 ml-8 mr-0 w-116)`,
                i % 2 === 0 ? tw`bg-primary-100` : tw`bg-white`,
              ]}
            >
              <div css={tw`mb-9`}>
                <Image image={card.image} style={tw`w-12 lg:(h-16)`} />
              </div>
              <Heading content={card.titleNode} headingType="h3" style={tw`mb-10 lg:mb-16`} />
              <div css={tw`flex flex-col divide-y divide-primary-500 divide-opacity-10`}>
                {(card.points || []).map((point, index) => (
                  <Text key={index} content={point} style={tw`py-4`} />
                ))}
              </div>
            </a>
          ))}
        </div>
      </div>
      <div
        css={tw`flex flex-row items-center w-full mt-8 pr-container-mobile
        lg:(justify-start pr-0 mx-auto)`}
      >
        <NavButton onClick={scrollPrev} enabled={prevBtnEnabled} reversed />
        <NavButton onClick={scrollNext} enabled={nextBtnEnabled} />
        <div
          css={tw`flex-1 bg-primary-500 bg-opacity-10 h-0.5 overflow-hidden relative ml-8
          lg:(flex-none w-1/2 ml-12)`}
        >
          <div
            css={css`
              ${tw`absolute inset-0 h-full transition-all duration-300 ease-in bg-primary-500`}
              width: calc(100% / ${cards.length});
              left: ${selectedIndex * (100 / cards.length)}%;
            `}
          />
        </div>
      </div>
    </div>
  )
}

CardCarousel.defaultProps = {
  cards: [],
}

CardCarousel.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default CardCarousel
