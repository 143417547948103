import PropTypes, { bool } from 'prop-types'
import React, { useState } from 'react'
import tw, { theme } from 'twin.macro'
import { global } from '../styles/global'
import { lg } from '../utils/breakpoints'
import { HTMLType, ImageType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Image from './image'
import Select from './select'
import Text from './text'

const selectStyle = (backgroundColor, hasError) => {
  const errorStyle = {
    control: {
      backgroundColor: theme`colors.red.500`,
    },
  }

  const disabledStyle = {
    control: {
      backgroundColor: theme`colors.smoke-grey`,
    },
  }

  return {
    control: (provided, { isDisabled }) => {
      return {
        ...provided,
        padding: '0.75rem 1.25rem',
        border: 'none',
        borderRadius: '0px',
        color: theme`colors.primary.500`,
        backgroundColor: backgroundColor || theme`colors.primary.50`,
        ...(hasError && errorStyle.control),
        ...(isDisabled && disabledStyle.control),
        boxShadow: 'none',
        minWidth: '8rem',
        [lg]: {
          ...provided[lg],
          padding: '1rem 1rem',
          minWidth: '14.25rem',
        },
      }
    },
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform 0.3s ease-in-out',
      transform: state.selectProps.menuIsOpen && 'scaleY(-1)',
      padding: '0',
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '0.875rem',
      lineHeight: '2',
      width: '21rem',
      [lg]: {
        ...provided[lg],
        fontSize: '1rem',
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided, { isDisabled }) => ({
      ...provided,
      display: isDisabled ? 'none' : 'flex',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '0px',
      border: 'none',
      borderRadius: '0px',
      boxShadow: 'none',
      transition: 'all 0.3s ease-in-out',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: '0px',
      paddingBottom: '0px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#666666',
      width: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '0.75rem 1.25rem 0.75rem 1.5rem',
      [lg]: {
        ...provided[lg],
        padding: '1rem 1.5rem 1rem 2rem',
      },
      color: '#666666',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      backgroundColor: theme`colors.white`,
      '&:hover': {
        backgroundColor: theme`colors.select-hover`,
      },
      border: 'none',
      borderRadius: '0px',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      padding: '0.75rem 1.25rem',
      [lg]: {
        ...provided[lg],
        padding: '1rem 1.5rem',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      color: theme`colors.primary.500`,
      padding: '0 0.25rem',
      [lg]: {
        ...provided[lg],
        padding: '0 0.5rem',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      color: theme`colors.primary.500`,
    }),
  }
}

const SelectBanner = ({
  image,
  imageMobile,
  title,
  description,
  placeholder,
  buttonLabel,
  options,
  style,
  isSelect,
}) => {
  const [selectedPlan, setSelectedPlan] = useState()

  return (
    <div css={[global`layout.container`, global`layout.grid`]}>
      <div
        css={[
          tw`absolute top-20 inset-x-6 flex flex-col items-start col-span-4 z-1 md:col-span-8
          lg:(relative top-auto inset-x-auto py-36 col-span-8) xl:(col-span-9)`,
          style,
        ]}
      >
        <Heading content={title} headingType="h3" />
        <Text content={description} style={tw`mt-4`} />
        <div>
          {isSelect ? (
            <div css={tw`flex flex-col items-start mt-10 lg:flex-row`}>
              <Select
                options={options}
                selectStyle={selectStyle}
                placeholder={placeholder}
                value={selectedPlan}
                onChange={setSelectedPlan}
              />
              <Button
                label={buttonLabel}
                link={selectedPlan ? selectedPlan.value : undefined}
                type="primary"
                size="base"
                theme="navy-yellow"
                style={tw`mt-6 lg:(mt-0 ml-4)`}
                hideCaret
              />
            </div>
          ) : (
            <div css={tw`flex flex-col items-start mt-10 lg:flex-row`}>
              <Button
                label={buttonLabel}
                link={options[0].value}
                type="primary"
                size="base"
                theme="navy-yellow"
                style={tw`mt-6 lg:(mt-0)`}
                hideCaret
              />
            </div>
          )}
        </div>
      </div>
      <div
        css={tw`col-span-4 h-186 -mx-container-mobile md:col-span-8 lg:(h-auto col-start-9 col-end-13 -mr-container-lg)
        xl:(col-start-10 col-end-17 -mr-container-xl) xxl:(-mr-container-xxl)`}
      >
        <Image image={image} imageMobile={imageMobile} style={tw`w-full h-full`} />
      </div>
    </div>
  )
}

SelectBanner.propTypes = {
  image: ImageType,
  imageMobile: ImageType,
  title: HTMLType,
  description: HTMLType,
  placeholder: HTMLType,
  buttonLabel: HTMLType,
  options: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
  isSelect: PropTypes.bool,
}

export default SelectBanner
